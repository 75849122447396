export default [
  {
    header: 'Транзакції',
  },
  {
    title: 'Балансові',
    route: 'apps-balance-list',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Паливні',
    route: 'apps-fuel-list',
    icon: 'TruckIcon',
  },
  {
    title: 'Рахунки',
    route: 'apps-accounts',
    icon: 'CreditCardIcon',
  }
];
