/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
import appsAndPages from './apps-and-pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import transactions from './transactions'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'
import discounts from "./discounts";


const role = JSON.parse(localStorage.getItem('userData')).role

let updatedAppsAndPages = [...appsAndPages]

if (role == 'manager') {
    updatedAppsAndPages = updatedAppsAndPages.filter(item => item.route != 'apps-users-list')
}

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
export default [...dashboard, ...transactions, ...updatedAppsAndPages, ...discounts, ...chartsAndMaps]