import axiosIns from "@/libs/axios";

class AuthService {
    code(phone) {
        return axiosIns
          .post('/auth/login', {phone: phone})
          .then(response => response.data)
          .catch(() => false);
    }

    login(phone, code) {
        return axiosIns
          .post('/auth/confirm', {code: code, phone: phone})
          .then(response => response.data)
          .catch(() => false);
    }

    profiles() {

        return axiosIns
          .get('/auth/profiles')
          .then(response => response.data)
          .catch(() => false);
    }
}

export default new AuthService();
