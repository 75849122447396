import {HISTORY_ACCESS} from '@/roles/access';

export default [{
  header: 'КОРИСТУВАЧІ',
},
{
  title: 'Групи водіїв',
  icon: 'UserPlusIcon',
  route: 'apps-groups-list',
},
{
  title: 'Водії',
  icon: 'UsersIcon',
  route: 'apps-drivers-list',
},
{
  title: 'Адміністратори',
  icon: 'UserIcon',
  route: 'apps-users-list',
},
{
  title: 'Історія',
  icon: 'ActivityIcon',
  route: 'logs',
  requiredProfileRole: HISTORY_ACCESS,
},
  /*{
        title: 'Імпорт',
        icon: 'UploadIcon',
        route: 'apps-import',
    },*/
];
