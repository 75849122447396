// export default [{
//     title: 'Кабінет',
//     icon: 'HomeIcon',
//     tagVariant: 'light-warning',
//     children: [{
//             title: 'Головна',
//             route: 'dashboard-ecommerce',
//         },
//         {
//             title: 'Аналітика',
//             route: 'dashboard-analytics',
//         }
//     ],
// }, ]

export default [{
    title: 'Головна',
    icon: 'HomeIcon',
    route: 'dashboard-ecommerce',
    // children: [{
    //         title: 'Головна',
    //     },
    //     {
    //         title: 'Аналітика',
    //         route: 'dashboard-analytics',
    //     }
    // ],
}, ]