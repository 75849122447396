export default [
    {
        header: 'Знижки',
    },
    {
        title: 'Знижки компанії',
        icon: 'PercentIcon',
        route: 'discounts',
    },
    {
        title: 'Налаштування',
        icon: 'SettingsIcon',
        route: 'discounts-all',
        requiredCompanyType: [2],
    },
    {
        title: 'Завдання',
        icon: 'TrendingDownIcon',
        route: 'discounts-changes',
        requiredCompanyType: [2],
    },
]