export default [{
        header: 'Інше',
    },
    {
        title: 'Моя компанія',
        route: 'pages-my-company',
        icon: 'TrelloIcon',
    },
    {
        title: 'Мапа АЗС',
        route: 'maps-leaflet',
        icon: 'MapIcon',
    },
    /* {
        title: 'FAQ',
        route: 'pages-faq',
        icon: 'HelpCircleIcon',
    },*/
]